import React, { useState } from "react";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <section className="p-6 rounded-lg shadow-2xl m-3 text-lg">
        <h1 className="text-3xl font-bold text-center">About Us</h1>
        <p className="my-3">
          Welcome to <b>Innovate Academy Online!</b>
        </p>
        <p className="my-3">
          At Innovate Academy Online, we are dedicated to fostering innovation
          and creativity through cutting-edge educational programs. Our mission
          is to provide learners with the tools, resources, and support needed
          to drive forward-thinking ideas and solutions.
        </p>

        <h6 className="font-bold mb-3 mt-6">Our Story</h6>
        <p className="my-2">
          Founded in 2024, Innovate Academy Online was established to bridge the
          gap between traditional education and the rapidly evolving demands of
          the modern world. We are committed to offering educational experiences
          that inspire and equip individuals to lead and innovate.
        </p>

        <h6 className="font-bold mb-3 mt-6">Our Team</h6>
        <p className="my-2">
          Our team includes experienced educators, industry leaders, and
          technology experts who are passionate about driving innovation. We
          work together to design and deliver high-quality programs that address
          the needs of today’s learners and professionals.
        </p>

        <h6 className="font-bold mb-3 mt-6">Our Services</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Innovative Courses:</b> A range of courses focused on the latest
            trends and technologies to help you stay ahead of the curve.
          </li>
          <li className="my-2">
            <b>Expert Instruction:</b> Learn from leading experts who bring
            real-world experience and insights to the classroom.
          </li>
          <li className="my-2">
            <b>Interactive Learning:</b> Engaging and interactive learning
            experiences designed to foster creativity and critical thinking.
          </li>
          <li className="my-2">
            <b>Professional Development:</b> Opportunities for skill enhancement
            and career growth through specialized programs.
          </li>
        </ul>

        <p className="my-2">
          Thank you for choosing Innovate Academy Online. We are excited to
          support your journey towards innovation and success.
        </p>
      </section>
    </>
  );
}
