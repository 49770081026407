import React from "react";
import { Link } from "react-router-dom";

export default function GameCard({ data }) {
  return (
    <div className="bg-sky-200 rounded-2xl shadow-sm shadow-sky-500">
      <div className="group hover:-rotate-6 origin-bottom-right duration-500 cursor-pointer group-hover:duration-500 overflow-hidden relative  rounded-2xl shadow-inner shadow-gray-50 flex flex-col justify-around items-center  bg-slate-700 text-gray-50">
        <Link to={`/single?slug=${data.slug}`}>
          <div className="after:duration-500 before:duration-500 duration-500  group-hover:before:translate-x-11 group-hover:before:-translate-y-11  group-hover:after:translate-x-11 group-hover:after:translate-y-16 after:absolute after:bg-orange-400 after:rounded-full after:-z-10 after:blur-xl after:bottom-32 after:right-16  before:absolute before:h-20 before:bg-sky-400 before:rounded-full before:-z-10 before:blur-xl before:top-20 before:right-16 flex flex-col font-extrabold text-6xl z-10">
            <img
              src={data.logo}
              alt={data.title}
              className="w-full h-64 object-fill hover:scale-105"
            />
          </div>
          <div className="flex flex-row justify-center text-lg  font-bold items-center gap-1">
            <span>{data.title}</span>
          </div>
        </Link>
      </div>
    </div>
  );
}
