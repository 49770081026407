import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const navLinks = [
    { path: "/", name: "home", label: "Home" },
    { path: "/about", name: "about", label: "About Us" },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      label: "Privacy Policy",
    },
    {
      path: "/term-condition",
      name: "term-condition",
      label: "Term Condition",
    },
    { path: "/contact-us", name: "contact-us", label: "Contact Us" },
  ];

  return (
    <footer className="text-white text-center text-md bg-slate-700 py-2">
      <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium rounded-lg md:space-x-8 md:flex-row md:mt-0 md:border-0 border-0 justify-center">
        {navLinks.map(({ path, name, label }) => (
          <li key={name}>
            <Link
              to={path}
              className={`block py-2 px-3 rounded hover:text-white hover:border hover:bg-indigo-700 border-indigo-700 focus:ring-0 focus:outline-none `}
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
      <p className="py-3">
        &copy; {currentYear}
        <span className="ms-2">innovateacademy.online</span>
      </p>
    </footer>
  );
}
