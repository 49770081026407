import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";

export default function Navbar() {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const [activeLink, setActiveLink] = useState(
    currentPath !== "" ? currentPath : "home"
  );

  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (link) => {
    setIsMenuOpen(true);
    setActiveLink(link);
  };

  const navLinks = [
    { path: "/", name: "home", label: "Home" },
    { path: "/about", name: "about", label: "About Us" },
    { path: "/contact-us", name: "contact-us", label: "Contact Us" },
  ];

  return (
    <header className="focus:ring-4 focus:outline-none p-2  text-white bg-slate-700 z-10">
      <nav className="border-gray-200 container mx-auto">
        <div className="flex flex-wrap items-center justify-between mx-auto px-4">
          <Link
            to="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
            onClick={() => handleLinkClick("home")}
          >
            {/* <h1 className="font-extrabold text-center text-5xl">Web 10</h1> */}
            <img
              src="/navbar.png"
              className="h-15"
              alt="innovateacademy.online"
            />
          </Link>
          <div className="flex">
            <button
              data-collapse-toggle="navbar-search"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden hover:bg-orange-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-search"
              aria-expanded="false"
              onClick={handleMenuToggle}
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className={`container items-center justify-between w-full md:flex md:w-auto md:order-1 md:my-3 my-0 md:mx-14 ${
              isMenuOpen ? "hidden" : "bg-indigo-950 rounded-xl mt-5"
            }`}
            id="navbar-search"
          >
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium rounded-lg md:space-x-8 md:flex-row md:mt-0 md:border-0 border-0">
              {navLinks.map(({ path, name, label }) => (
                <li key={name}>
                  <Link
                    to={path}
                    className={`block py-2 px-3 rounded hover:text-white hover:border hover:bg-indigo-700 border-indigo-700 focus:ring-0 focus:outline-none ${
                      activeLink === name ? "font-bold  bg-indigo-600" : ""
                    }`}
                    onClick={() => handleLinkClick(name)}
                  >
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
