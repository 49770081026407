import React, { useState } from "react";
import Loader from "./Loader";

export default function PrivacyPolicy() {
  const [isLoad, setIsLoad] = useState(true);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <section className="p-6 shadow-2xl rounded-lg m-3 text-lg">
        <h1 className="text-3xl font-bold text-center">Privacy Policy</h1>
        <p className="my-3">
          At Innovate Academy Online, we are committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, and
          safeguard your personal information.
        </p>

        <h6 className="font-bold mb-3 mt-6">1. Information We Collect</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Personal Information:</b> We collect personal information such as
            your name, email address, and contact details when you register on
            our website or use our services.
          </li>
          <li className="my-2">
            <b>Educational Information:</b> We collect information related to
            your learning activities, such as courses enrolled, progress, and
            assessments.
          </li>
          <li className="my-2">
            <b>Usage Data:</b> We collect information about how you use our
            website, including your IP address, browser type, and access times.
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">2. How We Use Your Information</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>To Provide Services:</b> We use your information to deliver and
            improve our educational content and services.
          </li>
          <li className="my-2">
            <b>To Improve Our Services:</b> We analyze usage data to enhance our
            website and learning tools.
          </li>
          <li className="my-2">
            <b>To Communicate:</b> We use your contact information to send
            important updates and respond to your inquiries.
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">3. Information Sharing</h6>
        <p className="my-3">
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties, except as required by law or to trusted third
          parties who assist us in operating our website and services.
        </p>

        <h6 className="font-bold mb-3 mt-6">4. Data Security</h6>
        <p className="my-3">
          We implement a variety of security measures to protect your personal
          information. However, no method of transmission over the internet or
          electronic storage is 100% secure.
        </p>

        <h6 className="font-bold mb-3 mt-6">5. Changes to This Policy</h6>
        <p className="my-3">
          We may update this Privacy Policy from time to time. Changes will be
          posted on this page, and the effective date will be updated.
        </p>

        <h6 className="font-bold mb-3 mt-6">6. Contact Us</h6>
        <p className="my-3">
          If you have any questions about this Privacy Policy, please contact us
          at privacy@innovateacademy.online.
        </p>
      </section>
    </>
  );
}
